import React, { useCallback, useEffect, useState } from "react";
import { useFormularioSig } from "../../../../hooks/administrativa/formularios-sig/useFormularioSig";
import CircularProgress from "@mui/material/CircularProgress";
import { TablaFormulariosSig, TablaFormulariosSigMe } from "../../../comons/TablaFormularios";
import { Modal } from 'antd';
import { DatosActividadFormAdd } from "../forms/datos-actividad";
import { FormularioSigFullView, FormularioSigFormUpdate, ChangeStatusForm, ChangeStatusFormMe } from "../forms/formularios-sig";
import { format } from 'date-fns';
import { useAuth } from "../../../../hooks/useAuth";
import { message } from "antd";
import { useAnalisisTrabajo } from "../../../../hooks/administrativa/formularios-sig/index";

  const headers = [
    { label: "ID Formulario", field: "Id_formulario" },
    { label: "Tipo Formulario", field: "tipoformulario" },
    { label: "Fecha de elaboración", field: "fecha_elaboracion" },
    { label: "Fecha de inicio", field: "fecha_inicio" },
    { label: "Fecha Final", field: "fecha_final" },
    { label: "Número de orden", field: "num_orden" },
    { label: "Empresa", field: "empresa" },
    { label: "Lugar de trabajo", field: "lugartrabajo" },
    { label: "Dirección", field: "direccion" },
    { label: "Número de trabajadores", field: "num_trabajadores" },
    { label: "No. llamada de servicios", field: "num_llamada_servicios" },
    { label: "Herramienta", field: "herramienta" },
    { label: "Trabajo", field: "trabajo" },
    { label: "Permiso de trabajo", field: "permisotrabajo" },
    { label: "Número de permiso", field: "num_permiso" },
    { label: "Altura", field: "altura" },
    { label: "Fecha de terminación", field: "Fechaterminacion" },
    { label: "Aprobador", field: "full_name_aprobador" },
    { label: "Ultimo cambio estado", field: "ultimo_estado" },
    { label: "Creador", field: "creador" },
  ];
  
  const popoverColumns = [
    { label: "ID Riesgo Potencial", field: "Id_riesgopotencial" },
    { label: "ID Control Riesgos", field: "Id_controlriesgos" },
    { label: "ID Condiciones Salud", field: "Id_condicionessalud" },
    { label: "ID Acceso Prevención", field: "Id_accesoprevencion" },
    { label: "ID Inspección Altura", field: "Id_inspeccionaltura" },
    { label: "ID Verificación Documentos", field: "Id_verificaciondoc" },
    { label: "ID Quien Elabora", field: "Id_quienelabora" },
    { label: "ID Quien Difunde", field: "Id_difundidoa" },
    { label: "ID Analisis Trabajo", field: "Id_analisistrabajo" },
    { label: "ID Supervisor ó Coordinador", field: "Id_supervisor" },
    { label: "ID ATS vinculado", field: "Id_ats" },
  ];

  const headersMe = [
    { label: "ID formulario difundido", field: "Id_difundidoa" },
    { label: "ID formulario inicial", field: "Id_formulario" },
    { label: "Tipo formulario", field: "ftipoformulario" },
    { label: "Fecha de elaboración", field: "ffechaElaboracion" },
    { label: "Fecha de inicio", field: "ffechaInicial" },
    { label: "Fecha Final", field: "ffechaFinal" },
    { label: "Fecha de terminación", field: "ffechaTerminacion" },
    { label: "Número de orden", field: "fnum_orden" },
    { label: "Empresa", field: "fempresa" },
    { label: "Lugar de trabajo", field: "flugartrabajo" },
    { label: "Dirección", field: "fdireccion" },
    { label: "Número de trabajadores", field: "fnum_trabajadores" },
    { label: "No. llamada de servicios", field: "num_llamada_servicios" },
    { label: "Herramienta", field: "fherramienta" },
    { label: "Trabajo", field: "ftrabajo" },
    { label: "Permiso de trabajo", field: "fpermisotrabajo" },
    { label: "Número de permiso", field: "fnum_permiso" },
    { label: "Altura", field: "faltura" },
    { label: "Aprobador", field: "faprobador_name" },
    { label: "Ultimo cambio estado", field: "fultimo_estado" },
    { label: "Creador", field: "fcreador" },
  ];
  
  const popoverColumnsMe = [
    { label: "Nombre de difundido", field: "difundidoNombre" },
    { label: "Cédula del difundido", field: "difundidoCedula" },
    { label: "Email del difundido", field: "difundidoEmail" },
    { label: "Cargo del difundido", field: "difundidoCargo" },
    { label: "Fecha registrado", field: "difundidoFecha" },
    { label: "Ultimo cambio de estado", field: "difundidoLastU" }
  ]

  
export  function FormularioSigTable() {

  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  const [refetch, setRefetch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [contentModal,  setContentModal] = useState(null);
  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const { loading, FormularioSig, getFormularioSigbyuser, SendEmailDifundidos, SendEmailSupervisor } = useFormularioSig();
  const { auth } = useAuth();
  const id_user = auth.me?.id_user;

  const { getAnalisisRiesgosID, AnalisisTrabajo } = useAnalisisTrabajo();

  /*Hook para traer los datos especificos de los formulario adyacentes*/ 
  useEffect(() => {
    getFormularioSigbyuser(id_user)
  }, [refetch])


  const handleAdd = useCallback(() => {
    setContentModal(
      <DatosActividadFormAdd
        onClose={openCloseModal} 
        onRefetch={onRefetch}
        FormularioSig={FormularioSig}
      />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  
  const handleUpdate = useCallback(
    (data) => {
      setContentModal(
        <FormularioSigFormUpdate
          onClose={openCloseModal}
          onRefetch={onRefetch}
          FormularioSigUpdate={data}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );

  const handleViewData = useCallback(
    (data) => {
      setContentModal(
        <FormularioSigFullView
          onClose={openCloseModal}
          FormularioSigView={data}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );
  
  const handleSendAprobDifundido = useCallback(
    async (data) => {
      try {
        await SendEmailDifundidos(data);
        message.success("La solicitud de aprobación difundidos ha sido enviada");
        
      } catch (error) {
        message.error("No se ha podido enviar el correo se aprobación")
      }
    },  
  );

  const handleSendAprobSupervisor = useCallback(
    async (data) => {
      try {
        await SendEmailSupervisor(data);
        message.success("La solicitud de aprobación difundidos ha sido enviada");
      } catch (error) {
        message.error("No se ha podido enviar el correo se aprobación")
      }
    },  
  );


  const transformedData = FormularioSig && FormularioSig.map(data => ({
    ...data,
    fecha_elaboracion: data.fecha_elaboracion && format(new Date(data.fecha_elaboracion), "yyyy-MM-dd HH:mm:ss"),
    fecha_inicio: data.fecha_inicio && format(new Date(data.fecha_inicio), "yyyy-MM-dd HH:mm:ss"),
    fecha_final: data.fecha_final && format(new Date(data.fecha_final), "yyyy-MM-dd HH:mm:ss"),
    Fechaterminacion: data.Fechaterminacion && format(new Date(data.Fechaterminacion), "yyyy-MM-dd HH:mm:ss"),
    permisotrabajo:  data.permisotrabajo ? 'Si' : 'No',
    num_permiso: data.num_permiso ? data.num_permiso : false,
  }))

  return (
   <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        
        <TablaFormulariosSig
          Data={transformedData}
          columns={headers}
          popoverColumns={popoverColumns}
          handleAgregarData={handleAdd}
          handleEditarData={handleUpdate}
          handleViewData={handleViewData}
          handleAprobacionDifundido={handleSendAprobDifundido}
          handleAprobacionAprobador={handleSendAprobSupervisor}
        />

      )}
      { /* Modal para Agregar y actualizar datos generales de los formularios */ }
      <Modal
        style={{
          top: 20,
        }}
        open={showModal}
        footer={null}
        onCancel={() => {openCloseModal(); onRefetch();}}
        width="60%"
        destroyOnClose
        maskClosable={false}
      >
        {contentModal}
      </Modal>
    </>
  )
}

export  function FormularioSigTableAdmin() {
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  const [refetch, setRefetch] = useState(false);
  const [showStatusModal, setStatusModal] = useState(false);
  const [contentStatusModal,  setContentStatusModal] = useState(null);
  const openCloseStatusModal = useCallback(() => setStatusModal((prev) => !prev), []);
  const [showModal, setShowModal] = useState(false);
  const [contentModal,  setContentModal] = useState(null);
  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const { loading, FormularioSig, getFormularioSig } = useFormularioSig();

  /*Hook para traer los datos especificos de los formulario adyacentes*/ 
  useEffect(() => {
    getFormularioSig()
  }, [refetch])


  const handleStatusForm = useCallback(
    (data) => {
      setContentStatusModal(
        <ChangeStatusForm
          onClose={openCloseStatusModal}
          onRefetch={onRefetch}
          FormularioSig={data}
        />
      );
      openCloseStatusModal();
    },
    [openCloseStatusModal, onRefetch]
  );

  const handleViewData = useCallback(
    (data) => {
      setContentModal(
        <FormularioSigFullView
          onClose={openCloseModal}
          FormularioSigView={data}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );
  

  const transformedData = FormularioSig && FormularioSig.map(data => {    
    return {
      ...data,
      fecha_elaboracion: data.fecha_elaboracion && format(new Date(data.fecha_elaboracion), "yyyy-MM-dd HH:mm:ss"),
      fecha_inicio: data.fecha_inicio && format(new Date(data.fecha_inicio), "yyyy-MM-dd HH:mm:ss"),
      fecha_final: data.fecha_final && format(new Date(data.fecha_final), "yyyy-MM-dd HH:mm:ss"),
      Fechaterminacion: data.Fechaterminacion && format(new Date(data.Fechaterminacion), "yyyy-MM-dd HH:mm:ss"),
      permisotrabajo: data.permisotrabajo ? 'Si' : 'No',
      num_permiso: data.num_permiso ? data.num_permiso : 'N/A',
    };
  });

  return (
   <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        
        <TablaFormulariosSig
          Data={transformedData}
          columns={headers}
          popoverColumns={popoverColumns}
          handleChangeStatus={handleStatusForm}
          handleViewData={handleViewData}
        />

      )}

      { /* Modal para cambiar el estado de aprobación */ }

      <Modal
        style={{
          top: 20,
        }}
        open={showStatusModal}
        onCancel={() => {openCloseStatusModal();}}
        footer={null}
        width=""
        title="Estado aprobación"
        destroyOnClose
        centered
        cancelButtonProps={false}
      >
        {contentStatusModal}
      </Modal>

      { /* Modal para visualizar toda la información del formulario */ }

      <Modal
        open={showModal}
        onCancel={() => {openCloseModal();}}
        width="80%"
        footer={null}
        title="Formularios SIG"
        destroyOnClose
        centered
      >
        {contentModal}
      </Modal>
      
    </>
  )
}

export  function FormularioSigTableAprobMe() {

  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  const [refetch, setRefetch] = useState(false);
  const [showStatusModal, setStatusModal] = useState(false);
  const [contentStatusModal,  setContentStatusModal] = useState(null);
  const openCloseStatusModal = useCallback(() => setStatusModal((prev) => !prev), []);
  const { loading, FormularioSig, getFormularioSigbyEmail } = useFormularioSig();
  const { auth } = useAuth();
  const email_user = auth.me?.email;


  /*Hook para traer los datos especificos de los formulario adyacentes*/ 
  useEffect(() => {
    getFormularioSigbyEmail(email_user)
  }, [refetch])

  const status = FormularioSig && FormularioSig.map(data => ({
    difundidoEstado: data.difundidos && data.difundidos.map(dato => ( dato.estado )),
  }))

  //console.log(status)

  const transformedData = FormularioSig && FormularioSig.map(data => ({
    ...data,
    Id_difundidoa: data.Id_difundidoa,
    Id_formulario: data.Id_formulario,
    //datos relacionados con el usuario difundido
    difundidoEstado: data.difundidos && data.difundidos.map(dato => ( dato.estado )),
    difundidoNombre: data.difundidos && data.difundidos.map(dato => ( dato.nombredifundido )),
    difundidoCedula: data.difundidos && data.difundidos.map(dato => ( dato.ceduladifundido )),
    difundidoEmail: data.difundidos && data.difundidos.map(dato => ( dato.emaildifundido )),
    difundidoCargo: data.difundidos && data.difundidos.map(dato => ( dato.cargodifundido )),
    difundidoFecha: data.difundidos && data.difundidos.map(dato => ( dato.fecha_difundido )),
    difundidoLastU: data.difundidos && data.difundidos.map(dato => (dato.last_update)),

    //datos relacionados con el formulario de datos iniciales
    ffechaElaboracion: data.formularios && data.formularios.map(dato => ( dato.fecha_elaboracion ) && format(new Date(dato.fecha_elaboracion), "yyyy-MM-dd HH:mm:ss") ),
    ffechaInicial: data.formularios && data.formularios.map(dato => ( dato.fecha_inicio ) && format(new Date(dato.fecha_inicio), "yyyy-MM-dd HH:mm:ss") ),
    ffechaFinal: data.formularios && data.formularios.map(dato => ( dato.fecha_final ) && format(new Date(dato.fecha_final), "yyyy-MM-dd HH:mm:ss") ),
    ffechaTerminacion: data.formularios && data.formularios.map(dato => ( dato.Fechaterminacion ) && format(new Date(dato.Fechaterminacion), "yyyy-MM-dd HH:mm:ss") ),
    ftipoformulario: data.formularios && data.formularios.map(dato => ( dato.tipoformulario )),
    fdireccion: data.formularios && data.formularios.map(dato => ( dato.direccion )),
    fnum_orden: data.formularios && data.formularios.map(dato => ( dato.num_orden )),
    fempresa: data.formularios && data.formularios.map(dato => ( dato.empresa )),
    flugartrabajo: data.formularios && data.formularios.map(dato => ( dato.lugartrabajo )),
    fnum_trabajadores: data.formularios && data.formularios.map(dato => ( dato.num_trabajadores )),
    fherramienta: data.formularios && data.formularios.map(dato => ( dato.herramienta )),
    ftrabajo: data.formularios && data.formularios.map(dato => ( dato.trabajo )),
    fpermisotrabajo: data.formularios && data.formularios.map(dato => ( dato.permisotrabajo )),
    fnum_permiso: data.formularios && data.formularios.map(dato => ( dato.num_permiso )),
    faltura: data.formularios && data.formularios.map(dato => ( dato.altura )),
    fcreador: data.formularios && data.formularios.map(dato => ( dato.creador )),
    faprobador_name: data.formularios && data.formularios.map(dato => ( dato.full_name_aprobador )),
    fultimo_estado: data.formularios && data.formularios.map(dato => ( dato.ultimo_estado )),
  }))

  const handleStatusForm = useCallback(
    (transformedData) => {
      setContentStatusModal(
        <ChangeStatusFormMe
          onClose={openCloseStatusModal}
          onRefetch={onRefetch}
          estadoDifundido={transformedData.difundidoEstado[0]}
          difundidoFormID={transformedData.Id_difundidoa}
          difundidoFormEmail={transformedData.difundidoEmail[0]}
        />
      );
      openCloseStatusModal();
    },
    [openCloseStatusModal, onRefetch]
  );

  return (
   <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        
        <TablaFormulariosSigMe
          Data={transformedData}
          columns={headersMe}
          popoverColumns={popoverColumnsMe}
          handleChangeStatus={handleStatusForm}
        />

      )}

      { /* Modal para cambiar el estado de aprobación */ }

      <Modal
        style={{
          top: 20,
        }}
        open={showStatusModal}
        onCancel={() => {openCloseStatusModal();}}
        footer={null}
        width=""
        title="Estado aprobación"
        destroyOnClose
        centered
        cancelButtonProps={false}
      >
        {contentStatusModal}
      </Modal>
    </>
  )
}