import {
  CommentOutlined,
  DesktopOutlined,
  PieChartOutlined,
  TeamOutlined,
  LogoutOutlined,
  BookOutlined,
  DollarOutlined,
  ToolOutlined,
  SettingOutlined,
  RobotOutlined

} from '@ant-design/icons';
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../hooks/useAuth';

export function Items() {
  let navigate = useNavigate();
  const { auth, logout } = useAuth();

  //primer condicional que permitirá acceder unicamente a los usuarios activos
  if(auth.me?.is_active){

  const items = [
    
      {
        key: "inicio",
        icon: <DesktopOutlined />,
        label: "Inicio",
        onClick: () => navigate("/home"),
      },

      {
        key: "syzia",
        icon: <RobotOutlined />,
        label: "SYZ IA",
        onClick: () => navigate("/syzia"),
      },

      //navigation y permisos para los usuarios administradores
      (auth.me?.admon_user_fullview) && {
          key: "Admon",
          label: "Configuración",
          icon: <SettingOutlined />,
          children: [
             {
              key: "usuarios",
              label: "Usuarios DA",
              onClick: () => navigate("/usuarios"),
            },
             
          ].filter(Boolean),
      },

      //navigation y permisos para los usuarios de universyz
      (auth.me?.universyz_only_view) && {

          key: "universyz",
          label: "Universyz",
          icon: <BookOutlined />,
          children: [
             {
              key: "archivos_universyz",
              label: "Cargue archivos",
              onClick: () => navigate("/archivos"),
            },
             
          ].filter(Boolean),
      },
      
      //navigation y permisos para los usuarios de comercial
      (auth.me?.comercial_only_general || auth.me?.comercial_only_view) && {

          key: "comercial",
          label: "Comercial",
          icon: <CommentOutlined />,
          children: [
             {
              key: "comerciales",
              label: "Comercial",
              onClick: () => navigate("/comercial"),
            },
            
             
          ].filter(Boolean),
      },

      //navigation y permisos para los usuarios de marketing
      (auth.me?.marketing_only_view) && {
        
          key: "marketing",
            label: "Marketing",
            icon: <PieChartOutlined />,
            children: [
              {
                key: "leads",
                label: "Leads",
                onClick: () => navigate("/leads"),
              },
              {
                key: "encuestas",
                label: "Encuestas satisfaccion",
                onClick: () => navigate("/encuestas-satisfaccion"),
              },
              {
                key: "pedidos",
                label: "NPS Y CES",
                onClick: () => navigate("/nps-y-ces"),
              },
               {
                key: "competencia",
                label: "Competencia",
                onClick: () => navigate("/competencia"),
              },
              {
                key: "Cargue_datos",
                label: "Cargue datos daater",
                onClick: () => navigate("/excel"),
              },
            ].filter(Boolean),
      },
      
      //navigation y permisos para los usuarios de servicios
      (auth.me?.servicios_only_view) && {
          key: "servicios",
          label: "Servicios",
          icon: <ToolOutlined />,
          children: [
             {
              key: "calendarios",
              label: "Agenda LDS",
              onClick: () => navigate("/calendario"),
            },
             
          ].filter(Boolean),
      },

      //navigation y permisos para los usuarios de administrativa
      {
          key: "administracion",
          label: "Administrativo",
          icon: <TeamOutlined />,
          children: [
            (auth.me?.administrativo_only_view) && {
              key: "linea-et",
              label: "Linea etica",
              onClick: () => navigate("/linea-etica"),
            },
            {
              key: "formularios-sig",
              label: "Formularios SIG",
              onClick: () => navigate("/formularios-sig"),
            },
            // {
            //   key: "solicitud-permisos",
            //   label: "Solicitud de permiso",
            //   onClick: () => navigate("/solicitud-permisos"),
            // },
          ].filter(Boolean),
      },
      // {
      //   key: "financiera",
      //   label: "Financiera",
      //   icon: <DollarOutlined />,
      //   children: [
      //     {
      //       key: "comisiones",
      //       label: "Comisiones",
      //       onClick: () => navigate("/comisiones"),
      //     },
           
      //   ].filter(Boolean),
      // },
      {
        key: "Logout",
        icon: <LogoutOutlined />,
        label: "Cerrar Sesión",
        onClick: logout,
      },      
  ];

  return {
    items
  };
  }
}
